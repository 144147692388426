import { DefaultProps } from 'utils/types';
import { usdFormatter } from 'utils/format';
import React from 'react';
import NumberRoller from './NumberRoller';

const Account = React.forwardRef<HTMLDivElement, DefaultProps & {
  amount: number,
}>((props, ref) => {
  return (
    <div ref={ref} className="flex items-center gap-5 bg-white rounded-full py-2.5 pl-5 pr-7 shadow">
      <svg fill="none" viewBox="0 0 42 22" width="42">
        <ellipse cx="21.44" cy="14.1" fill="#CDA412" rx="20.56" ry="7.9"/>
        <path fill="#CDA412" d="M.88 8.18H42v5.92H.88V8.18Z"/>
        <ellipse cx="21.5" cy="8" fill="#E8B500" stroke="#F5C105" strokeWidth="2" rx="19.5" ry="7"/>
        <ellipse cx="21.5" cy="8" stroke="#FFE49E" strokeDasharray="5 5" strokeWidth="2" rx="19.5" ry="7"/>
      </svg>
      <NumberRoller
        className="text-[32px] leading-10 text-magenta"
        initialAmount={props.amount}
        targetAmount={props.amount}
        format={usdFormatter.format}
      />
    </div>
  );
});

export default Account;
