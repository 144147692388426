import { DefaultProps, Vector2 } from 'utils/types';
import classNames from 'classnames';
import { ChipValue } from 'utils/types';
import TranslateFrom from './TranslateFrom';
import TranslateTo from './TranslateTo';

function getFill(value: ChipValue) {
  switch (value) {
    case 1: return '#C5C6C4';
    case 5: return '#F43E80';
    case 10: return '#00CFCF';
    case 25: return '#11DF63';
    case 50: return '#1157DF';
    case 100: return '#393939';
    case 500: return '#8748C6';
    default: return 'white';
  }
}

function getStroke(value: ChipValue) {
  switch (value) {
    case 1: return '#A4A4A4';
    case 5: return '#FCAED3';
    case 10: return '#87F5FC';
    case 25: return '#1A9A8E';
    case 50: return '#87A1FC';
    case 100: return '#DDC829';
    case 500: return '#D38BF4';
    default: return 'black';
  }
}

export default function Chip(props: DefaultProps & {
  value: ChipValue,
  translateFromElement?: HTMLElement,
  translateFromOffset?: Vector2,
  translateFromDelay?: number,
  translateToElement?: HTMLElement,
  translateToOffset?: Vector2,
  startTranslateTo?: boolean,
}) {
  return (
    <TranslateFrom
      element={props.translateFromElement}
      offset={props.translateFromOffset}
      delay={props.translateFromDelay}
      className={props.className}
    >
      <TranslateTo
        element={props.translateToElement}
        offset={props.translateToOffset}
        scale={0.4}
        start={!!props.startTranslateTo}
        className={classNames([
          'transition-[transform,opacity] duration-500',
          props.startTranslateTo ? 'opacity-0' : 'opacity-100',
        ])}
      >
        <div className="flex justify-center items-center shrink-0">
          <svg className="h-12 w-12 md:h-20 md:w-20" viewBox="0 0 74 74">
            <circle cx="37" cy="37" r="37" fill={getFill(props.value)}/>
            <circle cx="37" cy="37" r="35" fill="none" stroke={getStroke(props.value)} strokeDasharray="16 16" strokeWidth="4"/>
          </svg>
          <span className={classNames([
            'absolute font-black text-white',
            props.value >= 100 ? 'text-base md:text-2xl' : 'text-xl md:text-[32px]',
          ])}>{props.value}</span>
        </div>
      </TranslateTo>
    </TranslateFrom>
  );
}
