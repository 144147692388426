import React from 'react';
import { ChipValue, DefaultProps } from 'utils/types';
import Chip from './Chip';

const ChipButton = React.forwardRef<HTMLButtonElement, DefaultProps & {
  value: ChipValue,
}>((props, ref) => {
  return (
    <button
      ref={ref}
      className="rounded-full z-10 md:enabled:hover:scale-110 md:enabled:hover:shadow-lg md:enabled:active:scale-100 disabled:opacity-60 transition-[transform,opacity,box-shadow] ease-out duration-200"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Chip className="pointer-events-none" value={props.value} />
    </button>
  );
});

export default ChipButton;
