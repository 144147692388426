import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import React from 'react';

const Deck = React.forwardRef<HTMLButtonElement, DefaultProps>((props, ref) => {
  return (
    <button
      ref={ref}
      className={classNames([
        'flex flex-col justify-center items-center h-[108px] w-20 md:h-36 md:w-[102px] bg-grey-7 rounded-[13px] font-bold text-base text-grey-9',
        'md:enabled:hover:scale-105 md:enabled:hover:shadow-lg md:enabled:active:scale-100 transition-[transform,box-shadow] ease-out duration-200',
        props.className,
      ])}
      disabled={props.disabled}
      onClick={props.onClick}
    >Deck</button>
  );
});

export default Deck;
