import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function BarButton(props: DefaultProps & {
  text: string,
}) {
  return (
    <button
      className={classNames([
        'w-full pt-0 pb-1 px-0 transition-[padding,opacity] ease-out duration-100',
        'active:pt-1 active:pb-0',
        props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
        props.className,
      ])}
      onClick={props.onClick}
    >
      <div className={classNames([
        'pt-3.5 pb-3 bg-magenta font-bold text-[64px] leading-[77px] text-white shadow-md transition-[background-color,box-shadow] ease-out duration-100',
        'md:hover:bg-magenta-2 active:bg-magenta active:shadow',
      ])}>
        <span>{props.text}</span>
      </div>
    </button>
  );
}
