import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Modal from './Modal';
import NiceModal from '@ebay/nice-modal-react';
import Content from 'comps/modal/Content';
import ContentHeading from 'comps/modal/ContentHeading';

function PayoutsModal(props: DefaultProps) {
  return (
    <Modal
      className={classNames([
        '!z-30', // Push to front as must lie on top of 'Rules' modal.
        props.className,
      ])}
      title="Payouts"
    >
      <Content>
        <ContentHeading className="flex justify-between">
            <span>Hand Payouts</span>
            <span>% of bet</span>
        </ContentHeading>
        <div className="mt-2.5 flex flex-col gap-y-2 font-light">
          <div className="flex justify-between">
            <span>Life</span>
            <span>10%</span>
          </div>
          <div className="flex justify-between">
            <span>Super Life</span>
            <span>50%</span>
          </div>
          <div className="flex justify-between">
            <span>Wild</span>
            <span>20%</span>
          </div>
          <div className="flex justify-between">
            <span>Super Wild</span>
            <span>100%</span>
          </div>
        </div>
        <ContentHeading className="mt-5">Bonus Payouts</ContentHeading>
        <div className="mt-2.5 flex flex-col gap-y-2 font-light">
          <div className="flex justify-between">
            <span>2 of a kind</span>
            <span>10%</span>
          </div>
          <div className="flex justify-between">
            <span>3 of a kind</span>
            <span>50%</span>
          </div>
          <div className="flex justify-between">
            <span>4 of a kind</span>
            <span>100%</span>
          </div>
          <div className="flex justify-between">
            <span>5 of a kind</span>
            <span>500%</span>
          </div>
          <div className="flex justify-between">
            <span>6 of a kind</span>
            <span>1,000%</span>
          </div>
        </div>
        <p className="mt-2 font-normal">*Bonus Payouts Rules</p>
        <p>Only players who play the hand to the end will receive the bonus. This bonus is paid in addition to the standard hand payout. No bonus is paid if the Damnation card is drawn. If a Wild card is drawn, it will be applied to whatever suit will yield the highest payout for the player, however multiple wilds may not be applied to the same suit.</p>
      </Content>
    </Modal>
  );
}

export default NiceModal.create(PayoutsModal);
