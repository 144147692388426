import { CardSymbol, CardType } from 'utils/enums';
import { Bonus } from './Bonus';
import { Card } from './Card';

export class Stack {
  cards: Card[];
 
  constructor(cards: Card[] = []) {
    this.cards = cards;
  }

  push(card: Card) {
    this.cards.push(card);
  }
 
  getSymbol() {
    for (let i = 0; i < this.cards.length; i++) {
      const card = this.cards[i];
      if (card.symbol) {
        return card.symbol;
      }
    }
    return CardSymbol.None;
  }

  hasType(value: CardType) {
    for (let i = 0; i < this.cards.length; i++) {
      const card = this.cards[i];
      if (card.type === value) {
        return true;
      }
    }
    return false;
  }

  tryGetBonus() {
    const symbol = this.getSymbol();
    if (symbol === CardSymbol.None) {
      return null;
    }

    const size = this.cards.reduce((n, card) => {
      return n + Number(card.symbol === symbol || card.type === CardType.Wild);
    }, 0);

    return Bonus.isSizeValid(size) ? new Bonus(size) : null;
  }

  getBonusMultiplier() {
    const bonus = this.tryGetBonus();
    return bonus ? bonus.toMultiplier() : 0;
  }

  getMultiplier(includeBonus?: boolean) {
    let value = 0;
    this.cards.forEach(card => {
      value += card.getMultiplier();
    });

    if (includeBonus) {
      value += this.getBonusMultiplier();
    }
    return value;
  }
}
