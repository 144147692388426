import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import ActionButton from './ActionButton';

export default function FooterButtons(props: DefaultProps & {
  onClickContinue?: React.MouseEventHandler<HTMLButtonElement>,
  onClickWithdraw?: React.MouseEventHandler<HTMLButtonElement>,
}) {
  return (
    <div className={classNames([
      'flex gap-[5.64vw] md:gap-[max(2.51vw,38px)] transition-opacity',
      props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      props.className,
    ])}>
      <ActionButton
        text="Continue"
        disabled={props.disabled || !props.visible}
        visible={true}
        onClick={props.onClickContinue}
      />
      <ActionButton
        text="Withdraw"
        secondary
        disabled={props.disabled || !props.visible}
        visible={true}
        onClick={props.onClickWithdraw}
      />
    </div>
  );
}
