import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function ActionButton(props: DefaultProps & {
  text: string,
  secondary?: boolean,
}) {
  return (
    <button
      className={classNames([
        'group pb-1 pt-0 px-0 transition-[opacity,padding] ease-out duration-100',
        'enabled:active:pb-0 enabled:active:pt-1',
        props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
        props.className,
      ])}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <div className={classNames([
        'font-black text-white text-xl leading-6 pt-3.5 px-[18px] pb-4 rounded-[10px] shadow-md min-w-[162px] transition-[background,color,box-shadow] ease-out duration-100 md:py-5 md:px-11 md:text-[32px] md:leading-10',
        'group-enabled:active:shadow group-disabled:bg-grey-2 group-disabled:text-grey-6',
        props.secondary ? 'bg-mauve md:hover:bg-mauve-2 active:bg-mauve' : 'bg-magenta md:hover:bg-magenta-2 active:bg-magenta',
      ])}>
        <span>{props.text}</span>
      </div>
    </button>
  );
}
