import { DefaultProps } from 'utils/types';

export default function MenuLink(props: DefaultProps) {
  return (
    <button
      className="py-6 px-[max(6.92vw,27px)] font-bold text-xl leading-6 text-white text-right md:py-7 md:px-[max(2.51vw,38px)]"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
