import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Modal from './Modal';
import NiceModal from '@ebay/nice-modal-react';
import Content from 'comps/modal/Content';
import ContentHeading from 'comps/modal/ContentHeading';
import CardGrid, { Data as CardGridData } from 'comps/modal/CardGrid';
import { CardSymbol, CardType } from 'utils/enums';
import { symbols } from 'utils/deck';
import { Card } from 'models/Card';

type BlockData = {
  title: string,
  subtitle: string,
  cardGridDatas: CardGridData[],
};

const blockDatas: BlockData[] = [
  {
    title: '10 Super Life Cards',
    subtitle: '50% of bet',
    cardGridDatas: symbols.map(symbol => {
      return {
        cards: [new Card(CardType.Life, symbol, true)],
      }
    }),
  },
  {
    title: '30 Life Cards',
    subtitle: '10% of bet',
    cardGridDatas: symbols.map(symbol => {
      return {
        cards: Array(3).fill(null).map(() => {
          return new Card(CardType.Life, symbol);
        }),
      }
    }),
  },
  {
    title: '10 Death Cards',
    subtitle: 'Player loses bet',
    cardGridDatas: symbols.map(symbol => {
      return {
        cards: [new Card(CardType.Death, symbol)],
      }
    }),
  },
  {
    title: '1 Super Wild',
    subtitle: '100% of bet',
    cardGridDatas: [{
      cards: [new Card(CardType.Wild, CardSymbol.None, true)],
    }],
  },
  {
    title: '3 Wilds',
    subtitle: '20% of bet',
    cardGridDatas: [{
      cards: Array(3).fill(null).map(() => {
        return new Card(CardType.Wild, CardSymbol.None);
      }),
    }],
  },
  {
    title: '1 Super Death',
    subtitle: 'Player loses bet and winnings',
    cardGridDatas: [{
      cards: [new Card(CardType.Death, CardSymbol.None, true)],
    }],
  },
];

function DeckCompModal(props: DefaultProps) {
  return (
    <Modal
      className={classNames([
        props.className,
      ])}
      title="Deck Composition"
    >
      <Content>
        <div className="flex flex-col gap-y-16">
          {blockDatas.map((data, i) => {
            return (
              <div key={i} className="flex flex-col">
                <ContentHeading>{data.title}<br/><span className="font-normal">{data.subtitle}</span></ContentHeading>
                <CardGrid
                  className="mt-4 !grid-cols-5"
                  datas={data.cardGridDatas}
                  smallCards
                />
              </div>
            );
          })}
        </div>
        <div className="mt-10 h-0.5 bg-grey-5"></div>
        <p className="self-center font-bold text-[32px] leading-10 text-center uppercase">55 Total Cards</p>
      </Content>
    </Modal>
  );
}

export default NiceModal.create(DeckCompModal);
