import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Heading2(props: DefaultProps) {
  return (
    <h2
      className={classNames([
        'pt-[13px] pb-3 px-[30px] bg-grey-10 font-bold text-[32px] leading-10 text-white',
        props.className,
      ])}
    >{props.children}</h2>
  );
}
