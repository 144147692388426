import { Card } from 'models/Card';
import { Stack } from 'models/Stack';
import { removeItem } from './array';
import { CardType } from './enums';

const minStacks = 12;

export function generateStacks(cards: Card[], _optimiseWilds?: boolean) {
  const stacks: Stack[] = [];
  const _cards = cards.slice();

  while (_cards.length) {
    const card = _cards.shift();
    if (!card) {
      // Should never happen.
      break;
    }
    
    if (!tryAddToStacks(card, stacks)) {
      // Create a new stack with the card.
      stacks.push(new Stack([card]));
    }
  }

  if (_optimiseWilds) {
    optimiseWilds(stacks);
  }

  while (stacks.length < minStacks) {
    stacks.push(new Stack());
  }

  return stacks;
}

function tryAddToStacks(card: Card, stacks: Stack[]) {
  if (card.isDeath(true)) {
    // Super death must always be on own stack.
    return false;
  }

  for (let i = 0; i < stacks.length; i++) {
    const stack = stacks[i];

    if (stack.getSymbol() === card.symbol) {
      stack.push(card);
      return true;
    }
  }
  return false;
}

function optimiseWilds(stacks: Stack[]) {
  const wildStack = stacks.find(stack => stack.hasType(CardType.Wild));
  if (!wildStack) {
    return;
  }
  
  // Sort most valuable first.
  wildStack.cards.sort((a, b) => a.getMultiplier() - b.getMultiplier());

  // Assign to optimal stacks.
  for (let i = wildStack.cards.length - 1; i >= 0; i--) {
    const card = wildStack.cards[i];
    const optimalStack = tryGetOptimalWildStack(stacks);

    if (optimalStack) {
      wildStack.cards.splice(i, 1);
      optimalStack.push(card);
    }
  }
  wildStack.cards.forEach(card => {
    const optimalStack = tryGetOptimalWildStack(stacks);
    if (optimalStack) {
      removeItem(wildStack.cards, card);
      optimalStack.push(card);
    }
  });
}

function tryGetOptimalWildStack(stacks: Stack[]) {
  let maxSymbolMultiplier = -1;
  let optimalStack: Stack | null = null;

  for (let i = 0; i < stacks.length; i++) {
    const stack = stacks[i];
    if (stack.hasType(CardType.Wild)) {
      continue;
    }

    const bonusMultiplier = stack.getBonusMultiplier();
    if (bonusMultiplier > maxSymbolMultiplier) {
      optimalStack = stack;
      maxSymbolMultiplier = bonusMultiplier;
    }
  }

  return optimalStack;
}

export function getStacksMultiplier(stacks: Stack[], includeBonus?: boolean) {
  let value = 0;
  stacks.forEach(stack => {
    value += stack.getMultiplier(includeBonus);
  });
  return value;
}
