import Account from 'comps/gameplay/Account';
import MenuToggle from 'comps/gameplay/MenuToggle';
import CardStack from 'comps/gameplay/CardStack';
import ChipStack from 'comps/gameplay/ChipStack';
import Deck from 'comps/gameplay/Deck';
import ActionButton from 'comps/gameplay/ActionButton';
import { useMemo, useRef, useState } from 'react';
import Menu from 'comps/gameplay/Menu';
import { CardType, Stage } from 'utils/enums';
import { generateAllCards } from 'utils/deck';
import { assignToArrRef, shuffle, sum } from 'utils/array';
import { Card } from 'models/Card';
import { generateStacks, getStacksMultiplier } from 'utils/stacks';
import BarButton from 'comps/gameplay/BarButton';
import DeathOverlay from 'comps/gameplay/overlay/DeathOverlay';
import BonusOverlay from 'comps/gameplay/overlay/BonusOverlay';
import GainIndicator from 'comps/gameplay/GainIndicator';
import { delay } from 'utils/animation';
import classNames from 'classnames';
import Blocker from 'comps/gameplay/overlay/Blocker';
import ChipButtons from 'comps/gameplay/ChipButtons';
import FooterButtons from 'comps/gameplay/FooterButtons';
import { ChipValue } from 'utils/types';

const initialBank = 1000;
const allCards = generateAllCards();
const chipButtonValues: ChipValue[] = [10, 50, 100, 500];

function convertToChipValues(total: number) {
  // Order from large to small.
  const values: ChipValue[] = [500, 100, 50, 25, 10, 5, 1];
  const output: ChipValue[] = [];
  
  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    while (total >= value) {
      output.push(value);
      total -= value;
    }
  }
  return output;
}

export default function Gameplay() {
  const bankRef = useRef<HTMLDivElement>(null);
  const cardStacksRef = useRef<HTMLDivElement[]>([]);
  const cardsRef = useRef(allCards.slice());
  const winningsStackRef = useRef<HTMLDivElement>(null);
  const deckRef = useRef<HTMLButtonElement>(null);
  const chipButtonsRef = useRef<HTMLButtonElement[]>([]);

  const [stage, setStage] = useState<Stage>(Stage.Betting);
  const [bank, setBank] = useState(initialBank);
  const [dealt, setDealt] = useState<Card[]>([]);
  const [winnings, setWinnings] = useState<ChipValue[]>([]);
  const [bet, setBet] = useState<ChipValue[]>([]);
  const [lastChipButtonIndex, setLastChipButtonIndex] = useState(-1);
  const [wildStackIndex, setWildStackIndex] = useState(-1);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState(-1);
  const [sequencing, setSequencing] = useState(false);
  const [gain, setGain] = useState(0);
  const [gainVisible, setGainVisible] = useState(false);
  const [fadeGain, setFadeGain] = useState(false);
  const [betToBank, setBetToBank] = useState(false);
  const [dead, setDead] = useState(false);
  const [superDead, setSuperDead] = useState(false);
  const [bonusName, setBonusName] = useState('');
  const [bonusGain, setBonusGain] = useState(0);
  const [bonusVisible, setBonusVisible] = useState(false);
  const [fadeBonus, setFadeBonus] = useState(false);
  const [clearTable, setClearTable] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const stacks = useMemo(() => generateStacks(dealt, stage === Stage.Result), [dealt, stage]);
  const broke = useMemo(() => bet.length === 0 && bank < chipButtonValues[0], [bet, bank]);
  const showDealRestartButton = useMemo(() => stage === Stage.Betting && (bet.length > 0 || broke) && !sequencing, [stage, bet, broke, sequencing]);

  function handleClickChipButton(index: number) {
    setLastChipButtonIndex(index);
    const value = chipButtonValues[index];
    setBank(bank => bank - value);
    setBet(values => {
      const _values = values.slice();
      _values.push(value);
      return _values;
    });
  }

  async function deal() {
    setStage(Stage.Dealing);

    const card = cardsRef.current.pop();
    if (!card) {
      console.error('Deck is empty.');
      return;
    }

    // Block interaction for sequencing.
    setSequencing(true);

    const _dealt = dealt.slice();
    _dealt.push(card);
    setDealt(_dealt);

    // Wait for card to animate in.
    await delay(275);

    if (card.isDeath()) {
      // Show death overlay.
      setDead(true);
      setSuperDead(!!card.super);
      await delay(1700);

      // Remove death overlay and allow player to see board for a moment.
      setDead(false);
      await delay(800);

      // If not super death, apply bonuses
      if (!card.super) {
        // Find the stack with wilds in it (if there is one) and use it for wild animation 'from' anchor.
        const _wildStackIndex = stacks.findIndex(stack => stack.hasType(CardType.Wild));
        setWildStackIndex(_wildStackIndex);
  
        // Move to results stage. Wild placement will be optimised now (if there were wilds).
        setStage(Stage.Result);
        if (_wildStackIndex >= 0) {
          // Allow a moment for wilds to change stack.
          await delay(650);
        }
  
        const optimisedStacks = generateStacks(_dealt, true);
        const betSum = sum(bet);
        let hasBonus = false;
        
        // Iterate through any bonus stacks, adding the points.
        for (let i = 0; i < optimisedStacks.length; i++) {
          const stack = optimisedStacks[i];
          const stackBonus = stack.tryGetBonus();
  
          if (stackBonus) {
            // Show the bonus overlay, highlighting the corresponding stack on the board.
            setHighlightedStackIndex(i);
            const stackBonusGain = stackBonus.toMultiplier() * betSum;
            setBonusName(stackBonus.toString());
            setBonusGain(stackBonusGain);
            setBonusVisible(true);
            await delay(1800);
  
            // Fade the bonus gain into the winnings.
            setFadeBonus(true);
            await delay(300);
  
            // Bonus overlay can be hidden now
            setBonusVisible(false);
            await delay(100);
            setFadeBonus(false);
  
            // Increment winnings.
            setWinnings(values => {
              const _values = values.slice();
              _values.push(...convertToChipValues(stackBonusGain));
              return _values;
            });
            await delay(1000);
            hasBonus = true;
          }
        }
  
        if (hasBonus) {
          // Allow player to look at new score and board before clearing.
          await delay(500);
        }
  
        // Move winnings (with bonus) to bank.
        setBank(value => value + getStacksMultiplier(optimisedStacks, true) * betSum);
      }

      // Reset highlighted stack.
      setHighlightedStackIndex(-1);
      setWildStackIndex(-1);

    // Animate cards to deck and chips to their pots.
      setBetToBank(false);
      setClearTable(true);
      await delay(500);

      // Reset round.
      resetCards();
      setClearTable(false);
      setWinnings([]);
      setBet([]);
      setStage(Stage.Betting);

    } else {
      // Card hits stack, show gain!
      const _gain = card.getMultiplier() * sum(bet);
      setGain(_gain);
      setGainVisible(true);
  
      // Delay so the user can read the gain.
      await delay(1000);
  
      // Start fading the gain.
      setFadeGain(true);
  
      // Wait for gain to fade
      await delay(250);
  
      // Gain fades into winnings stack, so animate winnings increase.
      setWinnings(values => {
        const _values = values.slice();
        _values.push(...convertToChipValues(_gain));
        return _values;
      });
  
      // Can reset gain now as it has faded out anyway
      setGainVisible(false);
      setFadeGain(false);
    }
    
    // Release block.
    setSequencing(false);
  }

  async function withdraw() {
    // Block interaction for sequencing.
    setSequencing(true);

    // Move winnings (without bonus, but with bet) to bank.
    setBank(value => value + sum(bet) * (1 + getStacksMultiplier(generateStacks(dealt))));

    // Animate cards to deck and chips to their pots.
    setBetToBank(true);
    setClearTable(true);
    await delay(500);

    // Reset round.
    resetCards();
    setClearTable(false);
    setBetToBank(false);
    setWinnings([]);
    setBet([]);
    setStage(Stage.Betting);

    // Release block.
    setSequencing(false);
  }
  
  async function returnBet() {
    setSequencing(true);
    setClearTable(true);
    await delay(300);

    setBank(value => value + sum(bet));
    setBet([]);
    setClearTable(false);
    setSequencing(false);
  }

  function resetCards() {
    setDealt([]);
    shuffle(allCards);
    cardsRef.current = allCards.slice();
  }

  function startOver() {
    setBank(initialBank);
  }

  return (
    <div className="flex-1 flex flex-col bg-gradient-to-b from-grey-3 to-grey-4">
      {/* ——— Gameplay area ——— */}
      <div className="min-h-[81.6vh] relative flex flex-col justify-center py-5 px-[max(6.92%,27px)] md:pt-10 md:pb-0 md:px-[max(2.51%,38px)]">
        <div className="flex justify-between">
          <Account ref={bankRef} amount={bank} />
          <MenuToggle
            disabled={sequencing}
            pressed={isMenuOpen}
            onPressedChange={setMenuOpen}
          />
        </div>
        <div className="flex-1 mt-8 flex md:mt-9 xl:items-center">
          <div className="flex-1 grid grid-cols-4 gap-y-3 justify-items-center items-start sm:grid-cols-6 md:gap-y-4 xl:flex xl:justify-between 2xl:justify-center 2xl:gap-x-6">
            {stacks.map((stack, i) => {
              return (
                <CardStack
                  ref={el => assignToArrRef(cardStacksRef.current, el)}
                  key={i}
                  className={classNames([
                    i === highlightedStackIndex && 'z-30',
                  ])}
                  datas={stack.cards}
                  translateFromElement={stage === Stage.Result ? 
                    cardStacksRef.current[wildStackIndex] :
                    deckRef.current ?? undefined}
                  translateToElement={deckRef.current ?? undefined}
                  startTranslateTo={clearTable}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-7 flex justify-center items-end md:mt-9 md:items-center">
          <ChipStack
            ref={winningsStackRef}
            className="relative"
            label="Winnings"
            values={winnings}
            translateFromOffset={{
              x: -100,
              y: 0,
            }}
            translateToElement={!superDead && bankRef.current ? bankRef.current : undefined}
            translateToOffset={{
              x: -100,
              y: 0,
            }}
            startTranslateTo={clearTable}
          />
          <div className="flex-1"></div>
          <ChipStack
            className="absolute"
            label="Bet"
            values={bet}
            translateFromElement={chipButtonsRef.current[lastChipButtonIndex]}
            translateToElement={betToBank && bankRef.current ? bankRef.current : undefined}
            translateToOffset={{ x: 0, y: 120 }}
            startTranslateTo={clearTable}
            immediateNumbers
          />
          <Deck
            ref={deckRef}
            className="mb-2 md:mb-0"
            disabled={stage !== Stage.Betting || !bet.length || sequencing}
            onClick={returnBet}
          />
        </div>
        {/* ——— 'Deal'/'Start over' buttons ——— */}
        <ActionButton
          className="absolute self-center !py-1 !min-w-[198px] !font-bold !text-5xl !leading-[58px] md:hidden"
          text={broke ? 'Start over' : 'Deal!'}
          visible={showDealRestartButton}
          onClick={broke ? startOver : deal}
        />
        <BarButton
          className="mb-24 absolute left-0 hidden md:block"
          text={broke ? 'Start over' : 'Deal!'}
          visible={showDealRestartButton}
          onClick={broke ? startOver : deal}
        />
        <GainIndicator
          visible={gainVisible}
          amount={gain}
          fadeToElement={winningsStackRef.current ?? undefined}
          startFade={fadeGain}
        />
      </div>
      {/* ——— Footer area ——— */}
      <div className="flex-1 max-h-[18.4vh] flex flex-col justify-center items-center py-9 bg-grey-2 shadow-[-2px_-6px_12px_3px_rgba(110,170,185,0.1)] md:bg-transparent md:shadow-none">
        <ChipButtons
          values={chipButtonValues}
          buttonRef={el => assignToArrRef(chipButtonsRef.current, el)}
          visible={stage === Stage.Betting}
          valueDisabled={value => value > bank}
          onButtonClick={index => handleClickChipButton(index)}
        />
        <FooterButtons
          visible={stage !== Stage.Betting}
          disabled={sequencing}
          onClickContinue={deal}
          onClickWithdraw={withdraw}
        />
      </div>
      <Menu
        visible={isMenuOpen}
        onBlockerClick={() => setMenuOpen(false)}
      />
      <Blocker visible={dead || bonusVisible} />
      <DeathOverlay
        visible={dead}
        super={superDead}
      />
      <BonusOverlay
        visible={bonusVisible}
        name={bonusName}
        gain={bonusGain}
        fadeToElement={winningsStackRef.current ?? undefined}
        startFade={fadeBonus}
      />
    </div>
  );
}
