import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Overlay from './Overlay';

export default function DeathOverlay(props: DefaultProps & {
  super?: boolean,
}) {
  return (
    <Overlay
      className={classNames([
        'font-bold text-[64px] leading-[77px] uppercase select-none',
        props.className,
      ])}
      visible={props.visible}
    >
      {props.super ?
        <span className="text-red">Super<br/>Death</span> :
        <span>Death</span>
      }
    </Overlay>
  );
}
