import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Card from './Card';
import { Card as CardModel } from 'models/Card';
import React from 'react';

const CardStack = React.forwardRef<HTMLDivElement, DefaultProps & {
  datas: CardModel[],
  translateFromElement?: HTMLElement,
  translateToElement?: HTMLElement,
  startTranslateTo?: boolean,
}>((props, ref) => {
  return (
    <div
      ref={ref}
      className={classNames([
        'h-[98px] w-[69px] flex flex-col bg-grey rounded-[10px] shadow md:min-h-[125px] md:w-[88px] shrink-0',
        props.className,
      ])}
    >
      {props.datas.map((data, i) => {
        return (
          <Card
            key={i}
            className={classNames(i && '-mt-20 md:mt-[-101px]')}
            type={data.type}
            symbol={data.symbol}
            super={data.super}
            translateFromElement={props.translateFromElement}
            translateToElement={props.translateToElement}
            startTranslateTo={props.startTranslateTo}
          />
        );
      })}
    </div>
  );
});

export default CardStack;