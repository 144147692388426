import { Card } from 'models/Card';
import { shuffle } from './array';
import { CardSymbol, CardType } from './enums';

export const symbols = Object.values(CardSymbol).filter(v => v && !isNaN(Number(v))).map(v => Number(v));

export function generateAllCards() {
  const values: Card[] = [];

  // Add symbol cards
  symbols.forEach(symbol => {
    // 3 Life
    Array(3).fill(null).forEach(() => {
      values.push(new Card(CardType.Life, symbol));
    })

    // 1 Super Life
    values.push(new Card(CardType.Life, symbol, true));

    // 1 Death
    values.push(new Card(CardType.Death, symbol));
  });
  
  // Add wilds
  Array(3).fill(null).forEach(() => {
    values.push(new Card(CardType.Wild, CardSymbol.None));
  })
  
  // Add super wild
  values.push(new Card(CardType.Wild, CardSymbol.None, true));
  
  // Add super death
  values.push(new Card(CardType.Death, CardSymbol.None, true));

  shuffle(values);
  return values;
}
