import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function ContentHeading(props: DefaultProps) {
  return (
    <p className={classNames([
      'font-bold',
      props.className,
    ])}>{props.children}</p>
  );
}
