import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

type RowData = {
  name: string,
  value: string,
  negative?: boolean,
  neutral?: boolean,
}

interface Props extends DefaultProps {
  rowDatas: RowData[],
  resultData: RowData,
}

export default function NetCalculation(props: Props) {
  return (
    <div className={classNames([
      'mt-2 flex flex-col',
      props.className,
    ])}>
      {props.rowDatas.map((data, i) => {
        return (
          <div key={i} className="flex justify-between">
            <span>{data.name}</span>
            <span className={data.negative ? 'text-red' : (data.neutral ? 'text-grey-8' : 'text-green')}>{data.value}</span>
          </div>
        )
      })}
      <div className="my-2.5 h-px bg-grey-5"></div>
      <div className="flex justify-between font-normal">
        <span>{props.resultData.name}</span>
        <span className={props.resultData.negative ? 'text-red' : 'text-green'}>{props.resultData.value}</span>
      </div>
    </div>
  );
}
