import { ChipValue, DefaultProps, Vector2 } from 'utils/types';
import classNames from 'classnames';
import { usdFormatter } from 'utils/format';
import React from 'react';
import { sum } from 'utils/array';
import { usePrevious } from 'utils/hooks';
import NumberRoller from './NumberRoller';
import Chip from './Chip';

const ChipStack = React.forwardRef<HTMLDivElement, DefaultProps & {
  label: string,
  values: ChipValue[],
  translateFromElement?: HTMLElement,
  translateFromOffset?: Vector2,
  translateToElement?: HTMLElement,
  translateToOffset?: Vector2,
  startTranslateTo?: boolean,
  immediateNumbers?: boolean,
}>((props, ref) => {
  const prevValues = usePrevious(props.values);
  const numNew = props.values.length - (prevValues?.length ?? 0);

  // Limit number of rendered chips.
  const minVisibleIndex = props.values.length - 20;
  const minNewIndex = props.values.length - numNew;
  return (
    <div
      ref={ref}
      className={classNames([
        'flex flex-col items-center',
        props.className,
      ])}
    >
      <NumberRoller
        className="py-[3px] px-3 -mx-10 text-2xl bg-grey-6 rounded-full leading-7 text-white md:py-1.5 md:px-5 md:text-4xl md:leading-[44px]"
        initialAmount={0}
        targetAmount={sum(props.values)}
        format={usdFormatter.format}
        immediate={props.immediateNumbers}
      />
      <div className="mt-2.5 relative h-[74px] w-[74px] flex justify-center items-center rounded-full bg-grey shadow-lg md:mt-3 md:h-[122px] md:w-[122px]">
        {props.values.map((value, i) => {
          if (i < minVisibleIndex) {
            return null;
          }
          const newIndex = Math.max(i - minNewIndex, 0);
          return (
            <Chip
              key={i}
              className="absolute"
              value={value}
              translateFromElement={props.translateFromElement}
              translateFromOffset={props.translateFromOffset}
              translateFromDelay={newIndex * 90}
              translateToElement={props.translateToElement}
              translateToOffset={props.translateToOffset}
              startTranslateTo={props.startTranslateTo}
            />
          );
        })}
      </div>
      <span className="mt-1.5 font-bold text-base leading-5 text-grey-9 md:mt-4 md:text-3xl">{props.label}</span>
    </div>
  );
});

export default ChipStack;
