import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

interface Props extends DefaultProps {
  secondary?: boolean,
}

export default function FakeActionButton(props: Props) {
  return (
    <span className={classNames([
      'pt-2.5 pb-3 min-w-[123px] rounded-[10px] font-black text-base leading-5 text-center shadow-md',
      props.secondary ? 'bg-mauve' : 'bg-magenta',
      props.className,
    ])}>{props.children}</span>
  );
}
