import { DefaultProps } from 'utils/types';
import * as Toggle from '@radix-ui/react-toggle';
import classNames from 'classnames';

interface Props extends DefaultProps {
  pressed?: boolean,
  onPressedChange?: (pressed: boolean) => void,
}

export default function MenuToggle(props: Props) {
  return (
    <Toggle.Root
      className={classNames([
        'group z-20',
        props.className,
      ])}
      disabled={props.disabled}
      pressed={props.pressed}
      onPressedChange={props.onPressedChange}
    >
      <div className={classNames([
        'flex justify-center items-center bg-white rounded-full shadow h-[60px] w-[60px] transition-[transform,opacity]',
        'group-disabled:opacity-60',
        props.pressed && '-rotate-90',
      ])}>
        <svg className="stroke-grey-9" fill="none" viewBox="0 0 29 25" width="29">
          <path strokeLinecap="round" strokeWidth="3" d="M1.5 1.5h26M1.5 12.5h26M13.5 23.5h14"/>
        </svg>
      </div>
    </Toggle.Root>
  );
}
