import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Overlay(props: DefaultProps) {
  return (
    <div className={classNames([
      'fixed inset-0 flex flex-col justify-center items-center z-30 text-white text-center transition-opacity duration-200',
      props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      props.className,
    ])}>
      {props.children}
    </div>
  );
}
