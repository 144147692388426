import { DefaultProps, Vector2 } from 'utils/types';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

export default function TranslateTo(props: DefaultProps & {
  element?: HTMLElement,
  offset?: Vector2,
  scale?: number,
  start: boolean,
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  function setTranslation(value: Vector2) {
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(${value.x}px) translateY(${value.y}px) scale(${props.scale ?? 1})`;
    }
  }

  function resetTranslation() {
    if (containerRef.current) {
      containerRef.current.style.transform = 'none';
    }
  }

  useEffect(() => {
    if (props.start) {
      if (props.element && containerRef.current) {
        const elementRect = props.element.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        setTranslation({
          x: (elementRect.x + 0.5 * props.element.offsetWidth) - (containerRect.x + 0.5 * containerRef.current.offsetWidth),
          y: (elementRect.y + 0.5 * props.element.offsetHeight) - (containerRect.y + 0.5 * containerRef.current.offsetHeight),
        });
      } else if (props.offset) {
        setTranslation(props.offset);
      }
    } else {
      resetTranslation();
    }
  }, [props.start, props.element, props.offset]);

  return (
    <div
      ref={containerRef}
      className={classNames([
        'transition-transform',
        props.className,
      ])}
    >{props.children}</div>
  );
}
