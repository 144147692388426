import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Overlay from './Overlay';
import { usdFormatter } from 'utils/format';
import TranslateTo from '../TranslateTo';

export default function BonusOverlay(props: DefaultProps & {
  name: string,
  gain: number,
  fadeToElement?: HTMLElement,
  startFade: boolean,
}) {
  return (
    <Overlay
      className={classNames([
        props.className,
      ])}
      visible={props.visible}
    >
      <div className="flex-1 flex flex-col justify-end items-center">
        <TranslateTo
          element={props.fadeToElement}
          start={props.startFade}
          scale={0.3}
          className={classNames([
            'font-black whitespace-nowrap transition-[transform,opacity] duration-300',
            props.gain >= 100 ? 'text-7xl' : 'text-8xl',
            props.startFade ? 'opacity-0' : 'opacity-100',
            props.visible && 'animate-bulge animation-delay-75',
          ])}
        >+ {usdFormatter.format(props.gain)}</TranslateTo>
      </div>
      <div className="pt-4 pb-9 flex flex-col items-center">
        <span className="font-light text-5xl leading-58px ">{props.name}</span>
        <span className="font-black text-[76px] leading-[92px] uppercase">Bonus!</span>
      </div>
    </Overlay>
  );
}
