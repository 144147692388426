import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Content(props: DefaultProps) {
  return (
    <div
      className={classNames([
        'px-7 pt-6 pb-10 flex flex-col gap-3 font-extralight text-xl leading-6 text-white',
        props.className,
      ])}
    >{props.children}</div>
  );
}
