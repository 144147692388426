import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Modal from './Modal';
import NiceModal from '@ebay/nice-modal-react';
import Content from 'comps/modal/Content';
import Heading2 from 'comps/modal/Heading2';
import CardGrid from 'comps/modal/CardGrid';
import { CardType, CardSymbol } from 'utils/enums';
import NetCalculation from 'comps/modal/NetCalculation';
import FakeActionButton from 'comps/modal/FakeActionButton';
import ContentHeading from 'comps/modal/ContentHeading';
import { Card } from 'models/Card';

function ExamplesModal(props: DefaultProps) {
  return (
    <Modal
      className={classNames([
        props.className,
      ])}
      title="Examples"
    >
      <Heading2>Example 1</Heading2>
      <Content>
        <ContentHeading>Bet: $100</ContentHeading>
        <CardGrid
          datas={[
            {
              cards: [new Card(CardType.Life, CardSymbol.Luck, true)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Energy)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Energy)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Entertainment)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Death, CardSymbol.Science)],
              caption: '-$100',
              negative: true,
            },
          ]}
        />
        <NetCalculation
          rowDatas={[
            {
              name: 'Bet',
              value: '- $100',
              negative: true,
            },
            {
              name: 'Earned From Hand',
              value: '+ $80',
            },
            {
              name: 'Lifestyle Bonus (2 of a kind)',
              value: '+ $10',
            },
          ]}
          resultData={{
            name: 'Net Hand Value',
            value: '- $10',
            negative: true,
          }}
        />
      </Content>
      <Heading2>Example 2</Heading2>
      <Content>
        <ContentHeading>Bet: $500</ContentHeading>
        <CardGrid
          datas={[
            {
              cards: [new Card(CardType.Life, CardSymbol.Nature)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Technology, true)],
              caption: '+$250',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Energy)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Science, true)],
              caption: '+$250',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Nature)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Nutrition)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Wild, CardSymbol.None, true)],
              caption: '+$500',
            },
            {
              cards: [new Card(CardType.Death, CardSymbol.Science)],
              caption: '-$500',
              negative: true,
            },
          ]}
        />
        <NetCalculation
          rowDatas={[
            {
              name: 'Bet',
              value: '- $500',
              negative: true,
            },
            {
              name: 'Earned From Hand',
              value: '+ $1200',
            },
            {
              name: 'Bonus Tent (2 of a kind)',
              value: '+ $50',
            },
            {
              name: 'Bonus Potion (3 of a kind)',
              value: '+ $250',
            },
          ]}
          resultData={{
            name: 'Net Hand Value',
            value: '+ $1000',
          }}
        />
      </Content>
      <Heading2>Example 3</Heading2>
      <Content>
        <ContentHeading>Bet: $250</ContentHeading>
        <CardGrid
          datas={[
            {
              cards: [new Card(CardType.Life, CardSymbol.Adventure, true)],
              caption: '+$125',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Technology, true)],
              caption: '+$125',
            },
            {
              cards: [new Card(CardType.Wild, CardSymbol.None, true)],
              caption: '+$250',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Science, true)],
              caption: '+$125',
            },
          ]}
        />
        <div className="mt-1.5 flex items-center gap-3.5">
          <FakeActionButton secondary>Withdraw</FakeActionButton>
          <p>Player withdraws after 4 cards</p>
        </div>
        <NetCalculation
          rowDatas={[
            {
              name: 'Bet',
              value: '- $0',
              neutral: true,
            },
            {
              name: 'Earned From Hand',
              value: '+ $625',
            },
            {
              name: 'Bonus Potion (2 of a kind)',
              value: 'Forfeit',
              neutral: true,
            },
          ]}
          resultData={{
            name: 'Net Hand Value',
            value: '+ $625',
          }}
        />
      </Content>
      <Heading2>Example 4</Heading2>
      <Content>
        <ContentHeading>Bet: $100</ContentHeading>
        <CardGrid
          datas={[
            {
              cards: [new Card(CardType.Life, CardSymbol.Adventure, true)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Technology, true)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Wild, CardSymbol.None, true)],
              caption: '+$100',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Science, true)],
              caption: '+$50',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Science)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Science)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Life, CardSymbol.Entertainment)],
              caption: '+$10',
            },
            {
              cards: [new Card(CardType.Death, CardSymbol.None, true)],
              caption: 'Uh Oh',
              negative: true,
            },
          ]}
        />
        <NetCalculation
          rowDatas={[
            {
              name: 'Bet',
              value: '- $100',
              negative: true,
            },
            {
              name: 'Earned From Hand',
              value: '+ $0',
              neutral: true,
            },
            {
              name: 'Bonus Potion (4 of a kind)',
              value: 'Lost',
              negative: true,
            },
          ]}
          resultData={{
            name: 'Net Hand Value',
            value: '- $100',
            negative: true,
          }}
        />
      </Content>
    </Modal>
  );
}

export default NiceModal.create(ExamplesModal);
