import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Heading1(props: DefaultProps) {
  return (
    <div className={classNames([
      'flex flex-col items-start pt-[27px] pb-[33px] px-[25px]',
      props.className,
    ])}>
      <h1 className="font-bold text-2xl leading-7 text-white">{props.children}</h1>
      <div className="h-0.5 w-[60px] mt-[3px] bg-grey-5"></div>
    </div>
  );
}
