export class Bonus {
  static minSize = 2;
  static maxSize = 6;
  
  size: number;

  constructor(size: number) {
    if (!Bonus.isSizeValid(size)) {
      console.error(`Bonus size (${size}) out of range.`)
    }
    this.size = size;
  }

  static isSizeValid(value: number) {
    return value >= this.minSize && value <= this.maxSize;
  }

  toString() {
    return `${this.size} of a kind`;
  }

  toMultiplier() {
    switch (this.size) {
      case 2: return 0.1;
      case 3: return 0.5;
      case 4: return 1;
      case 5: return 5;
      case 6: return 10;
      default: return 0;
    }
  }
}
