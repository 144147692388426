import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import { usdFormatter } from 'utils/format';
import TranslateTo from './TranslateTo';

export default function GainIndicator(props: DefaultProps & {
  amount: number,
  fadeToElement?: HTMLElement,
  startFade: boolean,
}) {
  return (
    <TranslateTo
      element={props.fadeToElement}
      scale={0.3}
      className={classNames([
        'absolute self-center transition-[transform,opacity] duration-300 z-10',
        props.visible ? 'animate-bulge' : 'pointer-events-none',
        props.startFade ? 'opacity-0' : 'opacity-100',
        props.className,
      ])}
      start={props.startFade}
    >
      <span
        className={classNames([
          'font-black text-center text-grey-8 whitespace-nowrap drop-shadow-2xl transition-opacity duration-75',
          props.amount >= 100 ? 'text-7xl' : 'text-8xl',
          props.visible ? 'opacity-100' : 'opacity-0',
        ])}
      >+ {usdFormatter.format(props.amount)}</span>
    </TranslateTo>
  );
}
