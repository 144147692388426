export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function removeItem<T>(arr: Array<T>, value: T): Array<T> { 
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function sum(arr: number[]) {
  return arr.reduce((sum, value) => {
    return sum + value;
  }, 0);
}

export function assignToArrRef(arr: HTMLElement[], el: HTMLElement | null) {
  if (el && !arr.includes(el)) {
    arr.push(el)
  }
}
