import { DefaultProps, Vector2 } from 'utils/types';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function TranslateFrom(props: DefaultProps & {
  element?: HTMLElement,
  offset?: Vector2,
  delay?: number,
  zIndex?: number,
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const startedRef = useRef(false);
  const [visible, setVisible] = useState(false);

  function setTranslation(value: Vector2) {
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(${value.x}px) translateY(${value.y}px)`;
    }
  }

  function setZIndex(value: number | string) {
    if (containerRef.current) {
      containerRef.current.style.zIndex = value.toString();
    }
  }

  const getRequiredTranslation = useCallback(() => {
    if (props.element && containerRef.current) {
      const elementRect = props.element.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      return {
        x: (elementRect.x + 0.5 * props.element.offsetWidth) - (containerRect.x + 0.5 * containerRef.current.offsetWidth),
        y: (elementRect.y + 0.5 * props.element.offsetHeight) - (containerRect.y + 0.5 * containerRef.current.offsetHeight),
      };
    }
    return props.offset ?? { x: 0, y: 0 };
  }, [props.element, props.offset]);

  useEffect(() => {
    if (startedRef.current) {
      return;
    }
    setTranslation(getRequiredTranslation());
    // The element should be set visible on the next frame.
    setTimeout(() => {
      requestAnimationFrame(() => {
        // Raise z-index during animation, so goes over other elements.
        setZIndex((props.zIndex ?? 0) + 10);
        setVisible(true);
        setTimeout(() => setZIndex(props.zIndex ?? 'auto'), 300);
      });
    }, props.delay ?? 0);
    startedRef.current = true;
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames([
        visible ? [
          'visible !transform-none transition-transform ease-out',
          props.className,
        ] : 'invisible',
      ])}
    >
      {props.children}
    </div>
  );
}
