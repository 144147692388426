import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import { CardSymbol as Symbol, CardType as Type } from 'utils/enums';
import wildImg50 from 'assets/images/wild/50w.webp';
import wildImg100 from 'assets/images/wild/100w.webp';
import sdImg50 from 'assets/images/super-death/50w.webp';
import sdImg100 from 'assets/images/super-death/100w.webp';
import CardSymbol from './CardSymbol';
import TranslateFrom from './TranslateFrom';
import TranslateTo from './TranslateTo';

const wildImgSrcSet = `${wildImg50} 50w, ${wildImg100} 100w`;
const sdImgSrcSet = `${sdImg50} 50w, ${sdImg100} 100w`;

function typeToLetter(type: Type) {
  switch (type) {
    case Type.Life: return 'L';
    case Type.Death: return 'D';
    case Type.Wild: return 'W';
  }
}

function symbolToColor(symbol: Symbol) {
  switch (symbol) {
    case Symbol.Technology: return '#4E68B0';
    case Symbol.Adventure: return '#89509E';
    case Symbol.Entertainment: return '#A2CA39';
    case Symbol.Science: return '#33BDD0';
    case Symbol.Faith: return '#D92E27';
    case Symbol.Luck: return '#E9B320';
    case Symbol.Beauty: return '#F089B8';
    case Symbol.Nutrition: return '#B3A07C';
    case Symbol.Nature: return '#008F69';
    case Symbol.Energy: return '#F47E20';
  }
}

function getNotation(type: Type, _super?: boolean) {
  return (_super ? 'S' : '') + typeToLetter(type);
}

function getColor(type: Type, symbol: Symbol) {
  switch (type) {
    case Type.Life: return symbolToColor(symbol);
    case Type.Death: return 'black';
    case Type.Wild: return 'white';
  }
}

export default function Card(props: DefaultProps & {
  type: Type,
  symbol: Symbol,
  super?: boolean,
  smaller?: boolean,
  translateFromElement?: HTMLElement,
  translateToElement?: HTMLElement,
  startTranslateTo?: boolean,
}) {
  const isWild = props.type === Type.Wild;
  const isSuperDeath = props.type === Type.Death && props.super;
  const color = getColor(props.type, props.symbol);
  return (
    <TranslateFrom
      element={props.translateFromElement}
      className="duration-300"
    >
      <TranslateTo
        element={props.translateToElement}
        start={!!props.startTranslateTo}
        className={classNames([
          'transition-[transform,opacity] duration-500',
          props.startTranslateTo ? 'opacity-0' : 'opacity-100',
        ])}
      >
        <div
          className={classNames([
            'relative p-0.5 bg-red rounded-lg shadow-card',
            props.smaller ? 'h-[84px] w-[59px] md:h-[98px] md:w-[69px]' : 'h-[98px] w-[69px] md:h-[125px] md:w-[89px] md:p-[3px]',
            isWild && '!bg-gradient-wild-to-r',
            isSuperDeath && '!bg-red-2',
            props.className,
          ])}
          style={{
            backgroundColor: color,
          }}
        >
          <div
            className={classNames([
              'h-full flex flex-col justify-center items-center rounded-[6.5px] font-bold text-xs text-white uppercase md:pt-1.5 md:pb-[5px] md:px-2',
              props.smaller ? 'pt-0.5 pb-[2px] px-1 md:pt-1 md:pb-[3px] md:px-1.5' : 'pt-1 pb-[3px] px-1.5 md:pt-1.5 md:pb-[5px] md:px-2',
              isWild && 'text-transparent',
              isSuperDeath && 'text-red-2',
            ])}
            style={{
              backgroundColor: color,
            }}
          >
            <span className={classNames([
              'self-start',
              isWild && 'bg-clip-text bg-gradient-wild-to-b',
            ])}>{getNotation(props.type, props.super)}</span>
            <div className="flex-1"></div>
            {!isWild && !isSuperDeath && <CardSymbol
              className={classNames([
                'absolute',
                props.smaller ? 'scale-[0.86] md:scale-1' : 'md:scale-[1.29]',
              ])}
              type={props.symbol}
              fill={color}
            />}
            {(isWild || isSuperDeath) && <img
              className={classNames([
                'absolute',
                props.smaller ? 'w-8 md:w-10' : 'w-10 md:w-[50px]',
              ])}
              srcSet={isWild ? wildImgSrcSet : sdImgSrcSet}
              sizes={props.smaller ? '40px' : '50px'}
              alt={isWild ? 'Wild' : 'Super Death'}
            />}
            {props.super && <span className={classNames([
              isWild && 'bg-clip-text bg-gradient-wild-to-b',
            ])}>Super!</span>}
          </div>
        </div>
      </TranslateTo>
    </TranslateFrom>
  );
}
