import { ChipValue, DefaultProps } from 'utils/types';
import classNames from 'classnames';
import ChipButton from './ChipButton';

export default function ChipButtons(props: DefaultProps & {
  values: ChipValue[],
  buttonRef: (el: HTMLButtonElement|null) => void,
  valueDisabled: (value: number) => boolean,
  onButtonClick: (index: number) => void,
}) {
  return (
    <div className={classNames([
      'absolute flex gap-[max(4.62vw,18px)] md:gap-[max(1.92vw,29px)] transition-opacity',
      props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      props.className,
    ])}>
      {props.values.map((value, i) => {
        return (
          <ChipButton
            ref={el => props.buttonRef(el)}
            key={value}
            value={value}
            disabled={props.valueDisabled(value)}
            onClick={() => props.onButtonClick(i)}
          />
        )
      })}
    </div>
  );
}
