import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Heading3(props: DefaultProps) {
  return (
    <div className={classNames([
      'self-start flex flex-col',
      props.className,
    ])}>
      <h3 className="pl-7 pr-1 font-medium text-xl leading-6 text-white">{props.children}</h3>
      <div className="mt-[9px] h-[3px] bg-magenta"></div>
    </div>
  );
}
