import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import { useModal } from '@ebay/nice-modal-react';
import Heading1 from 'comps/modal/Heading1';
import CloseButton from 'comps/modal/CloseButton';
import { useRef } from 'react';

interface Props extends DefaultProps {
  title: string,
}

export default function Modal(props: Props) {
  const blocker = useRef<HTMLDivElement>(null);
  const modal = useModal();

  function handleBlockerClick(event: React.MouseEvent<HTMLElement>) {
    if (event.target === blocker.current) {
      modal.hide();
    }
  }
  return (
    <div
      ref={blocker}
      className={classNames([
        'fixed inset-0 z-20',
        !modal.visible && 'pointer-events-none',
        props.className,
      ])}
      onClick={handleBlockerClick}
    >
      <div className={classNames([
        'absolute top-0 bottom-0 right-0 w-full max-w-md flex flex-col bg-grey-11 overflow-y-auto transition-transform',
        !modal.visible && 'translate-x-full',
      ])}>
        <Heading1>{props.title}</Heading1>
        <CloseButton
          className="absolute top-0 right-0"
          onClick={modal.hide}
        />
        {props.children}
      </div>
    </div>
  );
}
