export enum Stage {
  Betting,
  Dealing,
  Result,
}

export enum CardType {
  None,
  Life,
  Death,
  Wild,
}

export enum CardSymbol {
  None,
  Technology,
  Adventure,
  Entertainment,
  Science,
  Faith,
  Luck,
  Beauty,
  Nutrition,
  Nature,
  Energy,
}
