import { CardSymbol as Type } from 'utils/enums';
import { DefaultProps } from 'utils/types';

interface Props extends DefaultProps {
  type: Type,
  fill?: string,
}

export default function CardSymbol(props: Props) {
  switch (props.type) {
    case Type.Technology: return (
      <svg className={props.className} viewBox="0 0 52.53 52.36" width="24" fill={props.fill} stroke="white" strokeMiterlimit="10" strokeWidth="3px">
        <rect width="33.79" height="33.79" x="9.37" y="9.37" rx="5.41" ry="5.41"/>
        <rect width="15.6" height="15.6" x="18.47" y="18.47" rx="3.52" ry="3.52"/>
        <path d="M15.86 9.37V0M26.19 9.37V0M36.67 9.37V0M0 15.7h9.37M0 26.03h9.37M0 36.51h9.37M36.76 42.98v9.38M26.43 42.98v9.38M15.94 42.98v9.38M52.53 36.51h-9.37M52.53 26.18h-9.37M52.53 15.7h-9.37"/>
      </svg>
    );
    case Type.Adventure: return (
      <svg className={props.className} viewBox="0 0 56.25 56.61" width="25" fill={props.fill} stroke="white" strokeMiterlimit="10" strokeWidth="3px">
        <path d="M21.44 50.6c.48-1.48 1.92-3.23 3.97-3.32.36-.02 1.97-.03 2.85 1.11 1.22 1.57.28 4.23-1.3 5.5-.92.74-2.53 1.36-3.96.72a3.18 3.18 0 0 1-1.58-1.57c-.44-1.03-.13-2 .01-2.45ZM45.88 29.81c-1.06-1.47-.16-3.56.63-4.58.32-.42 1.9-2.35 3.91-1.83a3.33 3.33 0 0 1 2.32 2.46c.31 1.41-.47 2.51-.92 3.14-.39.54-1.37 1.93-3.09 2.03-.13 0-1.92.08-2.85-1.21Z"/>
        <path d="m6.46 39.51.69-.68L37.59 8.59c.28-.45.68-1.09 1.19-1.84.82-1.21 1.22-1.81 1.65-2.31.66-.77 1.95-2.28 3.97-2.77a6.41 6.41 0 0 1 3.14.09 9.66 9.66 0 0 1 6.34 5.34c1.24 2.7.84 5.2.62 6.44a12.53 12.53 0 0 1-2.23 5.2L17.99 53.27c-.98.64-3.36 2-6.56 1.86-.3-.01-5.63-.34-8.42-4.7-.58-.9-2.01-3.15-1.36-5.69.61-2.39 2.78-3.92 3.47-4.41a10 10 0 0 1 1.33-.79Z"/>
      </svg>
    );
    case Type.Entertainment: return (
      <svg className={props.className} viewBox="0 0 45.41 55.53" width="20" fill={props.fill} stroke="white" strokeMiterlimit="10" strokeWidth="3px">
        <path d="M14.78 41.87c-4.32-1.99-8.84-1.61-11.33.92a6.72 6.72 0 0 0-1.94 4.8c.15 3.03 2.72 5.36 5.21 6.13 3.22 1 6.97-.41 9.2-3.58l-1.13-8.27-3.01-18.13a139.91 139.91 0 0 0 24.97-7.76l5.79 19.21c-.87-.16-2.13-.3-3.63-.16-1.36.12-3.68.32-5.82 1.94-.71.53-3.38 2.55-3.47 5.93-.02.61 0 3.08 1.84 4.8 2.42 2.28 6.35 1.7 8.74.24.22-.14 2.84-1.85 3.54-4.83.42-1.76.06-3.42-.28-4.96-.26-1.22-.58-2.02-.92-2.95-.16-.44-.84-2.57-2.19-6.84-1.13-3.57-.95-3.45-2.57-8.94-.89-3.02-1.06-3.47-2.48-8.13-1-3.3-1.89-6.29-2.65-8.88A85.93 85.93 0 0 1 9.24 13.52c.75 2.77 2.43 9.14 3.7 15.8a130.47 130.47 0 0 1 1.85 12.57Z"/>
      </svg>
    );
    case Type.Science: return (
      <svg className={props.className} viewBox="0 0 39.99 58.12" width="18" fill={props.fill} stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <path d="M38.35 35.87c-.12.1-.25.19-.37.27-2.87 1.92-6.48 2.51-9.91 2.18-3.44-.33-6.73-1.52-9.89-2.92-2.22-.99-4.41-2.1-6.76-2.69-2.93-.74-6.01-.66-9.01-.34a18.52 18.52 0 0 1 12.68-12.08V1.5h9.82v18.79a18.5 18.5 0 0 1 13.45 15.58ZM36.31 46.84a18.5 18.5 0 0 1-28.99 4.74 46.13 46.13 0 0 1 8.5-2.51 102.9 102.9 0 0 1 20.48-2.23Z"/>
        <path d="M38.4 40.03a18.35 18.35 0 0 1-2.09 6.81c-6.88.05-13.75.8-20.48 2.23a46.13 46.13 0 0 0-8.5 2.51 18.4 18.4 0 0 1-5.76-11.96c.32-.07.65-.12.97-.18 5.02-.82 10.15-.45 15.21.09 5.06.54 10.12 1.24 15.2 1.09 1.83-.05 3.67-.22 5.46-.6Z"/>
        <path d="M38.49 38.13c0 .64-.03 1.28-.09 1.9-1.79.38-3.63.55-5.46.6-5.08.15-10.14-.56-15.2-1.09-5.06-.54-10.19-.91-15.21-.09-.32.05-.65.11-.97.18a18.43 18.43 0 0 1 .85-7.26c3-.32 6.08-.4 9.01.34 2.35.59 4.54 1.7 6.76 2.69 3.15 1.4 6.45 2.59 9.89 2.92 3.43.32 7.04-.26 9.91-2.18.12-.08.25-.17.37-.27.09.74.14 1.5.14 2.26ZM28.21 1.5H11.78h16.43Z"/>
      </svg>
    );
    case Type.Faith: return (
      <svg className={props.className} viewBox="0 0 51.36 51.36" width="23" fill={props.fill}>
        <path d="M25.69 49.86A24.2 24.2 0 0 1 1.5 25.68 24.23 24.23 0 0 1 18.72 2.52 21.64 21.64 0 0 0 9.67 20.1a21.63 21.63 0 0 0 21.59 21.61c7.17 0 13.63-3.55 17.58-9.05a24.22 24.22 0 0 1-23.15 17.21Z"/>
        <path d="M11.7 7.84a23.13 23.13 0 0 0 19.55 35.38c4.47 0 8.69-1.29 12.26-3.53a22.64 22.64 0 0 1-17.83 8.69A22.67 22.67 0 0 1 11.69 7.85m14-7.85A25.69 25.69 0 1 0 51.2 22.72 20.1 20.1 0 1 1 28.66.17C27.69.05 26.7 0 25.69 0Z" fill="white"/>
        <path d="M33.88 21.13a9.07 9.07 0 0 0-2.79-2.78 9.73 9.73 0 0 0 2.78-2.85 10.8 10.8 0 0 0 2.86 2.87 10.27 10.27 0 0 0-2.85 2.77Z"/>
        <path d="m33.89 17.91.43.43-.42.41a8.32 8.32 0 0 0-.42-.42l.36-.37.05-.05m-.08-6.11a8.7 8.7 0 0 1-6.65 6.65c.66.1 2.8.5 4.57 2.37a7.97 7.97 0 0 1 2.08 4.28 8.46 8.46 0 0 1 6.65-6.65 9.3 9.3 0 0 1-6.65-6.65Z" fill="white"/>
      </svg>
    );
    case Type.Luck: return (
      <svg className={props.className}  viewBox="0 0 54.82 57.06" width="25" fill={props.fill}>
        <g stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
          <path d="m47.73 12.36-19.1 16.12c-.25.24-.75.62-1.41.65-.43.02-.83-.11-1.17-.23-.23-.08-.35-.14-.72-.31l-1.29-.58L2.9 19.23l-1.04-.33a3.5 3.5 0 0 1 .93-1.71L20.64 2.04a8.24 8.24 0 0 1 6.08.07c9.66 3.73 20.4 9.02 20.4 9.02l1.34.66-.73.56Z"/>
          <path d="M30.67 55.49a8.68 8.68 0 0 1-2.66-.06L7.74 47.67a9.27 9.27 0 0 1-3.25-5.09c-.59-2.26-1.78-10.16-2.94-20.96a5.99 5.99 0 0 1 .32-2.74c.39.13.73.22.96.32l21.95 9.11c.18.07.44.18.73.34.43.25.92.52 1.28 1.06.26.39.39.82.47 1.11 0 0 0 0 0 0l3.42 24.66Z"/>
          <path d="M50.02 15.16c-.14-.33-.27-1.3-.89-2.38a7.6 7.6 0 0 0-.67-.98l-.73.56a1742.12 1742.12 0 0 1-19.1 16.12l-.55.4c-.28.22-.53.41-.63.51-.11.11-.47.56-.09 1.91l3.31 24.19a6.73 6.73 0 0 0 3.23-1.16l18.6-14.68a3.46 3.46 0 0 0 .78-2.75c-.21-1.51-1.81-18.43-3.27-21.73Z"/>
        </g>
        <path d="M33.62 14.11c-1.06 0-1.64-.35-1.7-.49.04-.19.75-.83 2.16-1.01.22-.03.44-.04.65-.04 1.06 0 1.64.35 1.7.49-.04.19-.75.83-2.16 1.01-.22.03-.44.04-.65.04Z"/>
        <path d="M34.74 11.56c-.25 0-.52.02-.78.05-1.79.23-3.15 1.19-3.02 2.15.11.81 1.25 1.35 2.69 1.35.25 0 .52-.02.78-.05 1.79-.23 3.15-1.19 3.02-2.15-.11-.81-1.25-1.35-2.69-1.35Z" fill="white"/>
        <path d="M26.56 20.78c-1.06 0-1.64-.35-1.7-.49.03-.19.75-.83 2.16-1.01.22-.03.44-.04.65-.04 1.06 0 1.64.35 1.7.49-.03.19-.75.83-2.16 1.01-.22.03-.44.04-.65.04Z"/>
        <path d="M27.67 18.23c-.25 0-.52.02-.78.05-1.79.23-3.15 1.19-3.02 2.15.11.81 1.25 1.35 2.69 1.35.25 0 .52-.02.78-.05 1.79-.23 3.15-1.19 3.02-2.15-.11-.81-1.25-1.35-2.69-1.35Z" fill="white"/>
        <path d="M23.03 9.61c-1.06 0-1.64-.35-1.7-.49.03-.19.75-.83 2.16-1.01.22-.03.44-.04.65-.04 1.06 0 1.64.35 1.7.49-.03.19-.75.83-2.16 1.01-.22.03-.44.04-.65.04Z"/>
        <path d="M24.14 7.06c-.25 0-.52.02-.78.05-1.79.23-3.15 1.19-3.02 2.15.11.81 1.25 1.35 2.69 1.35.25 0 .52-.02.78-.05 1.79-.23 3.15-1.19 3.02-2.15-.11-.81-1.25-1.35-2.69-1.35Z" fill="white"/>
        <path d="M15.96 16.29c-1.06 0-1.64-.35-1.7-.49.03-.19.75-.83 2.16-1.01.22-.03.44-.04.65-.04 1.06 0 1.64.35 1.7.49-.03.19-.75.83-2.16 1.01-.22.03-.44.04-.65.04Z"/>
        <path d="M17.07 13.74c-.25 0-.52.02-.78.05-1.79.23-3.15 1.19-3.02 2.15.11.81 1.25 1.35 2.69 1.35.25 0 .52-.02.78-.05 1.79-.23 3.15-1.19 3.02-2.15-.11-.81-1.25-1.35-2.69-1.35Z" fill="white"/>
        <path d="M22.22 34.57a2.7 2.7 0 0 1-.94-.27 2.54 2.54 0 0 1-1.38-1.5c-.21-.7-.16-1.26-.03-1.37a.4.4 0 0 1 .22-.05 2.52 2.52 0 0 1 2.32 2.01c.18.94-.02 1.14-.02 1.14a.36.36 0 0 1-.16.03Z"/>
        <path d="M21 32.81c.08.08.15.16.21.25a1.73 1.73 0 0 1-.21-.25m-.9-2.42c-.29 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44a3.53 3.53 0 0 0 1.94 2.13c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M22.4 41.35a2.7 2.7 0 0 1-.94-.27 2.54 2.54 0 0 1-1.38-1.5c-.19-.63-.17-1.25-.03-1.37a.4.4 0 0 1 .22-.05 2.52 2.52 0 0 1 2.32 2.01c.18.94-.02 1.14-.02 1.14-.02.02-.09.03-.16.03Z"/>
        <path d="M21.17 39.59c.08.08.15.16.21.25a1.73 1.73 0 0 1-.21-.25m-.9-2.42c-.29 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44A3.53 3.53 0 0 0 21.06 42c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M23.31 48.21c-.33 0-.74-.18-.94-.27a2.54 2.54 0 0 1-1.38-1.5c-.21-.7-.16-1.26-.03-1.37.04-.03.12-.04.22-.04a2.52 2.52 0 0 1 2.32 2.01c.19.94-.02 1.14-.02 1.14-.02.02-.07.03-.16.03Z"/>
        <path d="M22.08 46.45c.08.08.15.16.21.25-.07-.07-.15-.15-.21-.25m-.9-2.42c-.3 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44a3.53 3.53 0 0 0 1.94 2.13c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M9.71 29.61a2.7 2.7 0 0 1-.94-.27 2.54 2.54 0 0 1-1.38-1.5c-.21-.7-.16-1.26-.03-1.37.04-.03.12-.04.22-.04a2.5 2.5 0 0 1 2.31 2.01c.18.95-.02 1.14-.02 1.14a.36.36 0 0 1-.16.03Z"/>
        <path d="M8.49 27.84c.08.08.15.16.21.25a1.84 1.84 0 0 1-.21-.25m-.9-2.42c-.3 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44a3.53 3.53 0 0 0 1.94 2.13c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M10.57 36.78a2.7 2.7 0 0 1-.94-.27 2.54 2.54 0 0 1-1.38-1.5c-.21-.7-.16-1.26-.03-1.37a.4.4 0 0 1 .22-.05 2.52 2.52 0 0 1 2.32 2.01c.18.94-.02 1.14-.02 1.14-.02.02-.09.03-.16.03Z"/>
        <path d="M9.35 35.02c.08.08.15.16.21.25-.07-.07-.15-.15-.21-.25m-.9-2.42c-.29 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44a3.53 3.53 0 0 0 1.94 2.13c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M11.09 43.53a2.7 2.7 0 0 1-.94-.27 2.54 2.54 0 0 1-1.38-1.5c-.19-.63-.17-1.25-.03-1.37a.4.4 0 0 1 .22-.05 2.52 2.52 0 0 1 2.32 2.01c.18.94-.02 1.14-.02 1.14-.02.02-.09.03-.16.03Z"/>
        <path d="M9.87 41.77c.08.08.15.16.21.25a1.73 1.73 0 0 1-.21-.25m-.9-2.42c-.29 0-.6.07-.84.26-.84.67-.36 2.27-.31 2.44a3.53 3.53 0 0 0 1.94 2.13c.26.11.8.35 1.34.35.28 0 .55-.06.78-.24.7-.55.46-1.77.39-2.12a3.5 3.5 0 0 0-3.3-2.82Z" fill="white"/>
        <path d="M44.54 23.9h-.06c-.05-.03-.16-.15-.25-.69-.12-.68.2-1.27.34-1.5.42-.67 1.25-1.25 1.62-1.25.15.17.19 1.18-.11 2.02a2 2 0 0 1-.63.97c-.26.22-.67.45-.92.45Z"/>
        <path d="M46.19 19.46c-.84 0-1.93.86-2.47 1.72a3.23 3.23 0 0 0-.48 2.2c.06.35.19 1.1.77 1.4.17.09.34.12.52.12.69 0 1.39-.54 1.55-.67.61-.5.84-1.13.94-1.42.35-1.01.48-2.78-.37-3.24a.92.92 0 0 0-.46-.11Z" fill="white"/>
        <path d="M46.83 37.83h-.06c-.05-.03-.15-.15-.25-.69-.12-.68.2-1.27.34-1.5.43-.67 1.25-1.25 1.62-1.25.15.17.19 1.18-.11 2.02a2 2 0 0 1-.63.97c-.32.26-.7.45-.92.45Z"/>
        <path d="M48.49 33.38c-.84 0-1.93.86-2.47 1.72a3.23 3.23 0 0 0-.48 2.2c.06.35.19 1.1.77 1.4.17.09.34.12.52.12.69 0 1.39-.54 1.55-.67.61-.5.84-1.13.94-1.42.35-1.01.48-2.78-.37-3.24a.92.92 0 0 0-.46-.11Z" fill="white"/>
        <path d="M40.15 35.68h-.06c-.05-.03-.16-.15-.25-.69-.11-.65.16-1.22.34-1.5.42-.67 1.24-1.25 1.62-1.25.15.17.19 1.18-.11 2.02a2 2 0 0 1-.63.97c-.27.22-.67.45-.92.45Z"/>
        <path d="M41.81 31.24c-.84 0-1.93.86-2.47 1.72a3.23 3.23 0 0 0-.48 2.2c.06.35.19 1.1.77 1.4.17.09.34.12.52.12.69 0 1.39-.54 1.55-.67.61-.5.84-1.13.94-1.42.35-1.01.48-2.78-.37-3.24a.92.92 0 0 0-.46-.11Z" fill="white"/>
        <path d="M33.92 32.55h-.06c-.05-.03-.16-.15-.25-.69-.12-.68.2-1.27.34-1.5.42-.67 1.25-1.25 1.62-1.25.15.17.19 1.18-.11 2.02a2.07 2.07 0 0 1-1.55 1.43Z"/>
        <path d="M35.58 28.1c-.84 0-1.93.86-2.47 1.72a3.18 3.18 0 0 0-.48 2.2c.06.35.19 1.1.77 1.4.17.09.34.12.52.12.69 0 1.39-.54 1.55-.67.61-.5.84-1.13.94-1.42.35-1.01.48-2.78-.37-3.24a.92.92 0 0 0-.46-.11Z" fill="white"/>
        <path d="M36.03 46.29h-.06c-.05-.03-.16-.15-.25-.69-.12-.68.2-1.27.34-1.5.42-.67 1.25-1.25 1.62-1.25.15.17.19 1.18-.11 2.02a2 2 0 0 1-.63.97c-.32.26-.7.45-.92.45Z"/>
        <path d="M37.69 41.85c-.84 0-1.93.86-2.47 1.72a3.23 3.23 0 0 0-.48 2.2c.06.35.19 1.1.77 1.4.17.09.34.12.52.12.69 0 1.39-.54 1.55-.67.61-.5.84-1.13.94-1.42.35-1.01.48-2.78-.37-3.24a.92.92 0 0 0-.46-.11Z" fill="white"/>
      </svg>
    );
    case Type.Beauty: return (
      <svg className={props.className} viewBox="0 0 55.25 60.12" width="25" fill={props.fill} stroke="white" strokeMiterlimit="10" strokeWidth="3px">
        <path d="M39.82 12.52c-.3-3.35-4.68-6.05-5.17-6.35-2.43-1.46-3.13-.63-7.3-2.61-2.65-1.26-3.5-2.13-5.34-2.05-1.24.05-1.32.47-4.16 1.58-3.84 1.5-4.78 1.17-5.95 2.42-.79.85-.66 1.32-1.61 3.95-1.34 3.71-1.96 3.78-2.75 6.15-.59 1.8-2 6.06-.2 8.52.72.98 2.42 2.33 8.05 1.95 11.05-.74 24.98-7.29 24.43-13.55ZM30.98 48.18c.13.53 1.08 4.47 3.99 5.38 1.69.53 3.28-.21 4.57-.8 2.34-1.09 3.97-3.21 7.13-7.39 2.49-3.3 3.09-4.56 3.06-6.02-.01-.64-.16-1.91-.71-3.32-.1-.25-.53-1.35-1.17-2.17-1.77-2.26-5.13-2.38-5.57-2.39-5.96-.13-13.04 9.52-11.3 16.72Z"/>
        <path d="M10.97 39.9a8.03 8.03 0 0 0-2.31 6.57c.25 1.73 1.59 3.31 4.27 6.47 2.12 2.49 3.31 3.37 3.91 3.78 1.38.94 2.8 1.9 4.68 1.91 1.36 0 2.4-.6 4.49-1.8 2.11-1.22 1.87-1.48 6.14-4.65 1.19-.89 1.49-1.08 1.74-1.59 1.08-2.26-1.36-5.48-1.94-6.24-4.5-5.95-16.12-9.16-20.98-4.43Z"/>
        <path d="M20.13 18.91c-2.67-1.38-6.48-1.3-9.29-.39-1.14.37-2.52 1.19-5.27 2.82-2.98 1.77-3.46 2.36-3.75 2.96-.64 1.32-.24 2.45.59 5.69 1.76 6.88 1.21 6.82 2.32 9.18 1.09 2.32 2.1 4.49 4.46 5.7 1.73.88 3.37.83 4.89.78 2.64-.08 5.77-.87 7.72-2.31 6.46-4.73 5.68-20.63-1.68-24.43ZM46.52 36.21c.79.12 3.39.51 5.01-.99.58-.54 1.03-1.33 1.6-5.41.48-3.43.72-5.14.58-6.18-.18-1.35-.44-1.51-3.68-7.92-1.65-3.27-1.99-4.03-3.09-4.9-1.67-1.3-3.62-1.69-4.68-1.91-.58-.12-2.1-.38-4.04-.19-1.13.11-1.99.19-2.78.57-2.76 1.32-3.21 5.57-3.29 6.29-.37 3.54 1.08 6.23 2.84 9.39 2.35 4.23 5.76 10.37 11.54 11.24Z"/>
        <path d="M41.77 26.76c1.38-3.11-.33-7.71-2.5-10.23-.29-.34-.06-.02-5.06-4.24-1.8-1.52-2.57-2.18-3.9-2.92-1.87-1.04-3.47-1.93-5.1-1.47-1.57.44-1.34 1.64-3.52 3.29-2.54 1.93-3.8 1.02-5.12 2.76-.69.9-.38 1.19-1.78 4.36-.81 1.85-.97 1.87-1.08 2.35-.6 2.68 3.31 6.14 6.03 7.79 7.29 4.4 19.55 3.89 22.02-1.69Z"/>
        <path d="M32.77 43.21c2.64.95 5.8-.46 7.56-1.89 1.46-1.2 2.36-3.77 4.1-8.87 1.72-5.06 1.61-6.01 1.19-6.79-.73-1.33-1.82-1.08-5.1-3.53-2.16-1.61-2.17-2.08-3.15-2.37-2.54-.76-5.67 1.38-7.21 3.12-4.99 5.65-3.08 18.27 2.62 20.33Z"/>
        <path d="M34.59 21.78c-1.01-1.81-3.32-2.21-6.5-2.77-2.48-.43-9.44-1.65-12.79 2.54a8.94 8.94 0 0 0-1.79 6.18c.26 2.58 1.66 3.13 1.59 5.62-.07 2.54-1.56 2.83-1.47 5.1.06 1.61 1.04 2.89 2.99 5.46 1.71 2.25 2.67 3.52 4.53 4.63.92.55 2.9 1.71 5.62 1.68 2.42-.02 4.08-.97 6.22-2.19 2.01-1.14 3.18-1.81 3.58-3.14.37-1.23-.15-2.26-1.1-4.45-1.38-3.2-2.21-5.14-2.56-6.5-1.45-5.63 3.5-8.89 1.68-12.16Z"/>
        <path d="M36.26 28.39a8.27 8.27 0 0 0-3.06-3.79 9.48 9.48 0 0 0-4.72-1.8 8.6 8.6 0 0 0-6.16 1.73c-1.45 1.19-2.03 2.6-2.57 3.92-.6 1.47-1.72 4.22-.74 7.46a9.1 9.1 0 0 0 3.39 4.77c.68.48 2.88 2.03 5.55 1.54 2.12-.39 3.34-1.85 4.67-3.43 1.45-1.72 5.15-6.12 3.63-10.4Z"/>
        <path d="M34.68 33.68s-1.17-1.41-3.12-2.88c-2.09-1.58-4-3.02-5.48-2.37-1.41.62-1.7 2.83-1.82 3.82-.1.79-.22 1.8.42 2.59.05.07.75.91 1.56.77.63-.1.98-.73 1.44-1.56.44-.78.43-1.08.94-2.3.56-1.35.84-2.02 1.3-2.11.46-.09.85.36 1.64 1.16 1.77 1.79 3.13 2.87 3.12 2.88Z"/>
      </svg>
    );
    case Type.Nutrition: return (
      <svg className={props.className} viewBox="0 0 77.05 58.55" width="35" fill={props.fill} stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <path d="M26.12 35.82c-.16-1.45-.5-3.51-1.26-5.9-.86-2.69-1.6-4.98-3.32-6.87-1.58-1.73-3.07-2.11-9.34-4.12-2.02-.65-5.28-1.71-9.39-3.09a21.87 21.87 0 0 1 7.79-4.52c5.54-1.79 7.67.16 15.29-.11 9.76-.36 12.33-3.78 17.81-1.78 3.71 1.35 6.08 4.21 7.47 6.32-1.64.33-5.98 1.46-8.83 5.34-2.43 3.3-2.76 7.19-2.85 8.42-.25 3.43.7 4.49-.06 5.75-.89 1.48-3.8 2.68-13.31.57Z"/>
        <path d="M51.98 39.19a24.31 24.31 0 0 1 5.72-4.56c4.52-2.58 9.12-3.23 13.14-3.79 1.25-.18 2.29-.28 3.01-.34-2.09-4.61-4.63-6.41-6.5-7.21-4.98-2.14-9.3.62-18.36 1.59a53.6 53.6 0 0 1-13.29-.25 9.17 9.17 0 0 1 3.17 3.17c1.27 2.16 1.32 4.49 1.38 6.86.02.98-.02 1.79-.06 2.36-3.69 6.14-5.17 12.07-2.9 14.5.97 1.04 2.88 1.71 5.65 2.68 1.93.68 3.59 1.08 4.76 1.33a18.95 18.95 0 0 1 1.14-11.62 17.6 17.6 0 0 1 3.13-4.72Z"/>
        <path d="M21.92 41.38a19.42 19.42 0 0 1 11.26 14.84 23.48 23.48 0 0 0 14.52-.68c-3.63-3.77-4.29-6.73-4.14-8.8.31-4.19 4.17-8.33 2.55-10.54-.16-.22-.23-.22-.76-.84a19.23 19.23 0 0 1-2.33-3.42l-21.11 9.45ZM24.01 13.74c8.18-1.22 16.65-2.38 24.94 1.57.84.4 1.51.77 1.92 1.01.07.1.25.38.45.37.3-.03.46-.67.53-.94.2-.8.61-3.75-1.36-5.8-.5-.53-.56-.36-3.12-2.02-1.2-.78-1.96-1.31-2.25-1.51-5.6-3.79-11.68-4.71-11.68-4.71-.6-.09-7.05-.99-13.6 1.74-3.07 1.28-4.41 2.63-8.02 3.66-.69.2-2.23.61-4 1.71-.28.18-1.51.96-2.62 2.13C2.84 13.42.63 18 1.89 19.32c1.14 1.19 4.27-1.16 11.33-3.31 4.19-1.28 7.35-1.75 10.81-2.27Z"/>
        <path d="M27.63 40.44a32.8 32.8 0 0 0-8.09 3.21 11.31 11.31 0 0 1 3.43-9.47 12.5 12.5 0 0 1 4.9-3.27c.62-.22 1.63-.52 5.55-.57 5.09-.07 7.7-.09 10.54 1.14 2.43 1.05 3.35 2.21 3.59 2.53a7.7 7.7 0 0 1 1.47 4.66 18.5 18.5 0 0 0-4.98-.16c-3.44.36-3.6 1.38-6.6 1.57-2.9.19-3.7-.7-7.1-.21-1.37.2-2.58.54-2.72.57ZM34.84 27.34c-.1-1.31-.13-4.2 1.73-6.57 1.34-1.71 3.98-2.33 9.11-3.58 3.47-.84 5.21-1.26 7.56-1.33 3.32-.09 5.89.5 8.09.99 2.13.48 3.37.77 4.97 1.56 1.83.9 2.91 1.86 4.97 3.69.69.61 2.98 2.69 3.81 4.61a5 5 0 0 1 .37 3.14c-.29 1.31-1.26 2.93-2.3 2.91-.9-.02-1.12-1.26-2.65-2.71-1.12-1.06-2.19-1.52-3.48-2.08-1.97-.86-4.11-1.26-8.69-1.25-4.84.02-4.35.48-7.39.39-6.25-.2-8.63-2.17-13.26-.9-1.26.35-2.23.81-2.83 1.13Z"/>
      </svg>
    );
    case Type.Nature: return (
      <svg className={props.className} viewBox="0 0 62.44 27.21" width="28" fill={props.fill} stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <path d="M15.48 1.5v24.21H1.5L15.48 1.5zM29.46 25.71H15.48V1.5l13.98 24.21z"/>
        <path d="M60.94 25.71H29.46L15.48 1.5h31.48l13.98 24.21z"/>
      </svg>
    );
    case Type.Energy: return (
      <svg className={props.className} viewBox="0 0 31.07 75.33" width="14" fill={props.fill} stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <path d="m1.5 20.39 12.77-4.5 1.26 7.38-.18 50.56L3.84 61.78 1.5 20.4Z"/>
        <path d="M3.66 12.12 16.07 1.5l-1.8 14.39-12.77 4.5 2.16-8.28ZM29.57 16.61 16.08 1.5l-1.8 14.39 1.26 7.38 5.22 1.44 8.82-8.1ZM15.35 73.83l5.4-49.12-5.22-1.44-.18 50.56Z"/>
        <path d="m29.57 16.61-1.8 43.9-12.41 13.31 5.4-49.12 8.82-8.1Z"/>
      </svg>
    );
    default: return null;
  }
}
