import { CardSymbol, CardType } from 'utils/enums';

export class Card {
  static lastId = 0;

  id: number;
  type: CardType;
  symbol: CardSymbol;
  super?: boolean;
 
  constructor(type: CardType, symbol: CardSymbol, _super?: boolean) {
    this.id = ++Card.lastId;
    this.type = type;
    this.symbol = symbol;
    this.super = _super;
  }

  getMultiplier() {
    switch (this.type) {
      case CardType.Life: return this.super ? 0.5 : 0.1;
      case CardType.Wild: return this.super ? 1 : 0.2;
      default: return 0;
    }
  }

  isDeath(requireSuper?: boolean) {
    return this.type === CardType.Death && (!requireSuper || !!this.super);
  }
}
