import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Blocker(props: DefaultProps) {
  return (
    <div className={classNames([
      'fixed inset-0 bg-black/80 z-20 transition-opacity duration-200',
      props.visible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      props.className,
    ])}></div>
  );
}
