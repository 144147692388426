import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function CloseButton(props: DefaultProps) {
  return (
    <button
      className={classNames([
        'p-6',
        props.className,
      ])}
      onClick={props.onClick}
    >
      <svg className="stroke-white" fill="none" viewBox="0 0 29 28" width="29">
        <path strokeLinecap="round" strokeWidth="3" d="M3.26 25.99 27.25 2M2.12 2l23.99 23.99"/>
      </svg>
    </button>
  );
}
