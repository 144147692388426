import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Modal from './Modal';
import NiceModal from '@ebay/nice-modal-react';
import Heading2 from 'comps/modal/Heading2';
import PayoutsModal from './PayoutsModal';
import Content from 'comps/modal/Content';
import { CardSymbol, CardType } from 'utils/enums';
import Heading3 from 'comps/modal/Heading3';
import CardGrid from 'comps/modal/CardGrid';
import NetCalculation from 'comps/modal/NetCalculation';
import Emphasise from 'comps/modal/Emphasise';
import Card from 'comps/gameplay/Card';
import FakeActionButton from 'comps/modal/FakeActionButton';
import ContentHeading from 'comps/modal/ContentHeading';
import { Card as CardModel } from 'models/Card';

function RulesModal(props: DefaultProps) {
  return (
    <Modal
      className={classNames([
        props.className,
      ])}
      title="Rules"
    >
      <Heading2>The Basics</Heading2>
      <Content>
        <p>The dealer will deal cards from the deck until a Death card is drawn. For each Life/Wild card that is drawn, the player will earn a percentage of their initial bet (see <button onClick={() => NiceModal.show(PayoutsModal)}><Emphasise>Payouts</Emphasise></button> for percentages).</p>
        <p>When the Death card is drawn, the player will lose their bet but keep whatever they have earned from the hand.</p>
        <CardGrid datas={[
          {
            cards: [new CardModel(CardType.Life, CardSymbol.Beauty)],
            caption: '+10%',
          },
          {
            cards: [new CardModel(CardType.Wild, CardSymbol.None)],
            caption: '+20%',
          },
          {
            cards: [new CardModel(CardType.Life, CardSymbol.Luck, true)],
            caption: '+50%',
          },
          {
            cards: [new CardModel(CardType.Death, CardSymbol.Nature)],
            caption: 'END',
          },
        ]} />
      </Content>
      <Heading2>Big Choices</Heading2>
      <Content>
        <p>There are only two choices for the player to make:</p>
      </Content>
      <Heading3>1 - How much to bet?</Heading3>
      <Content>
        <p>This one is pretty simple, the more you bet the more you stand to lose, <Emphasise>but</Emphasise> the more you will earn for each Life/Wild card that is drawn since payouts are a percentage of the player’s bet. This is a classic risk/reward proposition. Because this game is more volatile than other table games it is not recommended to bet more than 5-10% of total account value.</p>
        <p className="font-normal">Initial Bet: $200</p>
        <CardGrid datas={[
          {
            cards: [new CardModel(CardType.Life, CardSymbol.Beauty)],
            caption: '+$20',
          },
          {
            cards: [new CardModel(CardType.Wild, CardSymbol.None)],
            caption: '+$40',
          },
          {
            cards: [new CardModel(CardType.Life, CardSymbol.Luck, true)],
            caption: '+$100',
          },
          {
            cards: [new CardModel(CardType.Death, CardSymbol.Nature)],
            caption: 'END',
          },
        ]} />
        <NetCalculation
          rowDatas={[
            {
              name: 'Bet',
              value: '- $200',
              negative: true,
            },
            {
              name: 'Earned From Hand',
              value: '+ $160',
            },
          ]}
          resultData={{
            name: 'Net Hand Value',
            value: '- $40',
            negative: true,
          }}
        />
      </Content>
      <Heading3>2 - Continue or Withdraw?</Heading3>
      <Content>
        <p>Beyond the initial wager, there is one other meaningful way in which the player controls their fate. This choice gets made between each card that is dealt.</p>
        <FakeActionButton className="self-start my-1">Continue</FakeActionButton>
        <p>When the player chooses continue, they commit to see another card.  If it is Death, the hand will end and the player will lose their bet.  If it is Life/Wild the player will get paid and choose again to continue or withdraw before the next card.</p>
        <FakeActionButton className="self-start my-1" secondary>Withdraw</FakeActionButton>
        <p>If the player withdraws they get to keep everything they have won from the hand up to that point <Emphasise>as well as</Emphasise> their initial bet.  In doing so they are no longer a part of the hand and cannot re-enter.  They also forfeit the lifestyle bonus that would get paid at the end of the hand.</p>
      </Content>
      <Heading2>Lifestyle Bonus</Heading2>
      <Content>
        <p>The lifestyle bonus is an additional bonus that gets paid to all players who remain in the hand until a Death card is drawn.  To make a lifestyle bonus there must be multiple cards of the same suit (or a wild card that helps to make a match).  The more cards of the same suit, the bigger the bonus.  Keep in mind, each Death card is suited as well and will potentially help to improve the bonus.</p>
        <p>Multiple Lifestyle Bonuses can be paid on the same hand, for instance there could be two suits with 3 of a kind and one suit with 2 of a kind and each of them would pay the associated bonus.</p>
        <p>The Wild cards can will be applied to whatever suit will make the largest bonus, however <Emphasise>only one</Emphasise> wild card can be applied to any given suit.  If multiple Wilds are drawn they must be distributed to different suits to make multiple bonuses.</p>
        <CardGrid datas={[
          {
            cards: [
              new CardModel(CardType.Life, CardSymbol.Nature),
              new CardModel(CardType.Life, CardSymbol.Nature),
              new CardModel(CardType.Wild, CardSymbol.None, true),
            ],
            caption: '+50%',
          },
          {
            cards: [
              new CardModel(CardType.Life, CardSymbol.Energy),
              new CardModel(CardType.Life, CardSymbol.Energy),
              new CardModel(CardType.Wild, CardSymbol.None),
            ],
            caption: '+50%',
          },
          {
            cards: [
              new CardModel(CardType.Life, CardSymbol.Entertainment),
              new CardModel(CardType.Death, CardSymbol.Entertainment),
            ],
            caption: '+10%',
          },
          {
            cards: [new CardModel(CardType.Life, CardSymbol.Adventure)],
            caption: 'NA',
          },
        ]} />
      </Content>
      <Heading2>Super Death</Heading2>
      <Content>
        <p><Emphasise className="text-red">BEWARE THE SUPER DEATH</Emphasise>.  If this card is drawn all players who have not withdrawn from the hand will lose EVERYTHING including their bet, their winnings from the hand, and whatever lifestyle bonuses would have been made.</p>
        <Card type={CardType.Death} symbol={CardSymbol.None} super />
      </Content>
      <Content className="bg-grey-10">
        <ContentHeading>THAT’S IT!</ContentHeading>
        <p>Play a few games to get a feel for it and then see how far you can push your luck!</p>
        <Emphasise>Will you live a long and prosperous life or will you join the ranks of the damned?!</Emphasise>
      </Content>
    </Modal>
  );
}

export default NiceModal.create(RulesModal);
