import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import MenuLink from './MenuLink';
import { useRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import RulesModal from 'modals/RulesModal';
import PayoutsModal from 'modals/PayoutsModal';
import DeckCompModal from 'modals/DeckCompModal';
import ExamplesModal from 'modals/ExamplesModal';

interface Props extends DefaultProps {
  visible?: boolean,
  onBlockerClick?: React.MouseEventHandler<HTMLElement>,
}

export default function Menu(props: Props) {
  const blocker = useRef<HTMLDivElement>(null);
  
  function handleBlockerClick(event: React.MouseEvent<HTMLElement>) {
    if (event.target === blocker.current && props.onBlockerClick) {
      props.onBlockerClick(event);
    }
  }
  return (
    <div
      ref={blocker}
      className={classNames([
        'fixed inset-0 z-10',
        !props.visible && 'pointer-events-none',
      ])}
      onClick={handleBlockerClick}
    >
      <div className={classNames([
        'absolute top-0 bottom-0 right-0 w-[312px] pt-24 overflow-y-auto scrollbar-hide bg-black/80 backdrop-blur-[2px] transition-transform md:pt-[120px]',
        !props.visible && 'translate-x-[312px]',
      ])}>
        <div className="flex flex-col">
          <MenuLink onClick={() => NiceModal.show(RulesModal)}>Rules</MenuLink>
          <MenuLink onClick={() => NiceModal.show(PayoutsModal)}>Payouts</MenuLink>
          <MenuLink onClick={() => NiceModal.show(DeckCompModal)}>Deck Composition</MenuLink>
          <MenuLink onClick={() => NiceModal.show(ExamplesModal)}>Examples</MenuLink>
        </div>
      </div>
    </div>
  );
}
