import { DefaultProps } from 'utils/types';
import classNames from 'classnames';

export default function Emphasise(props: DefaultProps) {
  return (
    <span className={classNames([
      'font-medium',
      props.className,
    ])}><i>{props.children}</i></span>
  );
}
