import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import Card from 'comps/gameplay/Card';
import { Card as CardModel } from 'models/Card';

export type Data = {
  cards: CardModel[],
  caption?: string,
  negative?: boolean,
}

interface Props extends DefaultProps {
  datas: Data[],
  smallCards?: boolean,
}

export default function CardGrid(props: Props) {
  return (
    <div className={classNames([
      'mt-1.5 grid grid-cols-4 gap-2 font-light text-xl leading-6',
      props.className,
    ])}>
      {props.datas.map((data, i) => {
        return (
          <div key={i} className="flex flex-col justify-between items-center">
            <div className="flex flex-col">
              {data.cards.map((card, i) => {
                return (
                  <Card
                    key={i}
                    className={classNames(i && (props.smallCards ? '-mt-16 md:-mt-20' : '-mt-20 md:mt-[-101px]'))}
                    type={card.type}
                    symbol={card.symbol}
                    super={card.super}
                    smaller={props.smallCards}
                  />
                )
              })}
            </div>
            {data.caption && <span className={classNames([
              'mt-2',
              data.negative && 'text-red',
            ])}>{data.caption}</span>}
          </div>
        )
      })}
    </div>
  );
}
