import { DefaultProps } from 'utils/types';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useAnimate } from 'utils/hooks';

export default function NumberRoller(props: DefaultProps & {
  initialAmount: number,
  targetAmount: number,
  format: (value: number) => string,
  immediate?: boolean,
}) {
  const containerRef = useRef<HTMLSpanElement>(null);
  const amountRef = useRef(props.initialAmount);
  const targetAmountRef = useRef(props.targetAmount);
  const distRef = useRef(0);
  const immediateRef = useRef(props.immediate);

  useEffect(() => {
    targetAmountRef.current = props.targetAmount;
    if (immediateRef.current || targetAmountRef.current < amountRef.current) {
      amountRef.current = props.targetAmount;
    } else {
      distRef.current = Math.abs(targetAmountRef.current - amountRef.current);
    }
  }, [props.targetAmount]);

  useAnimate((deltaTime) => {
    if (amountRef.current !== undefined && containerRef.current) {
      if (amountRef.current < targetAmountRef.current) {
        amountRef.current += 2 * distRef.current * deltaTime / 1000;
        amountRef.current = Math.min(amountRef.current, targetAmountRef.current);
      }
      containerRef.current.textContent = props.format(amountRef.current);
    }
  });
  return (
    <span ref={containerRef} className={classNames([
      props.className,
    ])}></span>
  );
}
